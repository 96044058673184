.react-bootstrap-table {

  thead th {
    cursor: pointer;
  }

  .dropup>.caret {
    margin: 10px 0;
    color: #cccccc;
  }

  .dropup .caret,
  .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
  }

  th>.react-bootstrap-table-sort-order>.caret {
    margin: 10px 6.5px;
  }
}

.react-bootstrap-table,
.inner-custom-pagination {

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

.dtable-icon {
  font-size: 20px;
  line-height: 1;
  display: inline-block;
  margin-top: -8px;
  margin-bottom: -8px;
}

.dtable-actions {
  margin-top: -8px;
  margin-bottom: -8px;

  .btn {
    font-size: 20px;
    line-height: 1;
  }
}

.search-box .form-control {
  border-radius: 0.25rem;
}

.close-dtable-filters-btn {
  position: absolute;
  top: 1px;
  right: 3px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.react-bs-table-no-data {
  text-align: center;
  border-bottom: none;
}

.table .badge-lg {
  margin-top: -5px;
  margin-bottom: -5px;
}

.bc-back-btn {
  font-size: 16px;
  font-weight: inherit;
  line-height: inherit;
  padding: 5px 0;
  margin: -5px 0;
  border: none;
  vertical-align: top;
}

@include media-breakpoint-down(sm) {

  .search-box {
    width: 100%;
  }
}

.value-sep {
  padding: 0 3px;
}

.table-empty {
  border-bottom-width: 0 !important;
  text-align: center;
}

.paginated-table-card {
  margin-bottom: 120px; // to account for the size-per-page dropdown
}