body[data-sidebar="dark"] .navbar-brand-box,
.full-width-layout .navbar-brand-box {
  background-color: #1D71B8;
  height: 70px;
}

body {
  // change page background ans left/right space

  @media(max-width: 768px) {
    background: #EFEFEF;

    .page-content {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

.notarized-badge {
  background: $cyan;
  padding: 4px 8px;
  border-radius: 30px;
  font-size: 8px;
  line-height: 12px;
  color: $white;
}

.tracking-number {
  color: #2B69B8;
  border: 1px solid #2B69B8;
  border-radius: 12px;
  padding: 0.2rem 0.5rem;
  margin-right: 2rem;

  @media(max-width: 768px) {
    color: #556EE6;
    border: 1px solid #556EE6;
    padding: 4px 10px;
    margin: 0 !important;
    font-size: 13px !important;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    display: inline-block;
    border-radius: 86px;
  }
}

.bordred-btn {
  border: 1px solid #EFF2F7;
  padding: 5px 8px;
  border-radius: 4px;
}

.full-width-layout {
  background-color: $body-bg;

  .logo-light {
    display: block;
  }

  #vertical-menu-btn {
    display: none;
  }

  .vertical-menu {
    bottom: auto;
    padding-bottom: 0;
  }

  .main-content {
    margin-left: 0;
  }
}

.logo-size-33 {
  width: 33px;
  height: 33px;
}

.main-content {
  // fill the viewport vertically
  min-height: 100vh;
}

.footer {
  // expand all the way to the left
  left: 0;
  // display over the vertical menu
  z-index: 1001;
}

.vertical-menu {
  // footer height + some margin
  padding-bottom: 70px;
}

.spinner-chase.sm {
  width: 32px;
  height: 32px;
}

.spinner-chase.dtable {
  // give some bottom spacing to the spinner
  // datatable row height - spinner height
  margin-bottom: calc(44.5px - 32px);
}

.is-invalid .select2-selection__control {
  // show a red border around an invalid select2 control
  // to match the error styling of the other controls
  border-color: #f34943 !important;
}

.select2-selection__menu {
  // display over the footer
  z-index: 1002 !important;
}

.card-header:first-child {
  // add rounded borders to the card header
  border-radius: 8px 8px 0 0;
}

#preloader.inner {
  background-color: transparent;
  // account for the vertical-menu
  // so the preloader appears centered in the right-side frame
  left: 250px;
}

.card.expand-v {
  // expand card vertically as much as possible
  // so adjacent cards will be equal in height
  height: calc(100% - 24px);
}

.badge-lg {
  padding: 8px;
  font-size: inherit;
}

.rounded-lg {
  border-radius: 20px;
}

.me-45 {
  // intermediate value between .me-4 and .me-5
  margin-right: 2rem !important;
}

.card-menu-btn {
  font-size: 20px;
  line-height: 1;
  color: $secondary;
}

.section-rows {
  margin-bottom: 0;

  td {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }

  .notary-profile & {
    border-top: 1px solid $gray-100;
  }
}

.section-row-label {
  width: 30%;
}

.section-row-value {
  font-size: 16px;
  font-weight: 500;
}

.card-section {
  padding: 0 $card-spacer-x;
}

.card,
.card-section {

  &.blue {
    background-color: #EEF4FD;
  }
}

.btn-faded {
  opacity: 0.65;
}

.table {

  &.bt-1 {
    border-top-width: 1px;
    border-top-style: solid;
  }

  &.bb-0 tr:last-child td {
    border-bottom-width: 0;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.form-check-input:disabled+.form-check-label {
  // disabled checkboxes/switches should appear as not clickable
  cursor: default;
}

.progress-button {
  position: relative;

  &.disabled {
    // do not fade using opacity because that affects the entire button
    opacity: 1;

    &.btn-primary {
      // set a faded shade of color only for background and border
      background-color: #958fee;
      border-color: #958fee;
    }
  }

  .btn-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $primary;
  }

  span {
    position: relative;
  }
}

.page-title-box h4 {
  // since we are combining page title with breadcrumbs
  // do not make title upercase by default
  // we want to have the flexibility to choose on a case by case basis
  text-transform: none;
}

.editable-content-text {
  cursor: pointer;
  color: $primary;
}

.top-heading {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
  font-size: 16px;
}

.auth-user-name {
  vertical-align: middle;
  line-height: 1.2;
}

.auth-user-role {
  display: block;
  font-size: 70%;
}

.divider-faded {
  height: 1px;
  background-color: $gray-200;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  margin: 20px 0;
}

.divider-faded.divider-sm {
  @media screen and (max-width: 768px) {
    margin: 10px 0;
  }
}

.word-wrap-normal {
  word-wrap: normal;
}

.bg-orange {
  background-color: $orange-400;
}

.icon-btn-rounded {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  color: $black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background-color: $primary;
    color: $white;
    pointer-events: none;
  }
}

.vertical-divider {
  height: 32px;
  width: 1px;
}

.form-check-input-lg {
  width: 16px;
  height: 17.75px;
  margin: 0;
}

.square-icon-btn {
  width: 36px;
  height: 36px;
  padding: 0;

  i {
    font-size: 20px;
  }
}

.form-check-area {
  @media(min-width: 768px) {
    &:last-child {
      margin-left: 25px;
    }
  }
}

.collect-form-title {
  color: #74788D;
  font-size: 14px;
  font-weight: 500;

  @media(max-width: 768px) {
    font-size: 13px;
    color: #495057;
    padding-left: 10px;
  }
}

.bordered-section {

  @media(max-width: 768px) {
    border: 1px solid #EFF2F7;
    border-radius: 4px;
    padding: 10px;
    font-size: 12px;
    line-height: 18px;
    color: #495057;
  }
}

.text-right {
  text-align: right;
}

.info-text {
  font-size: 10px;
  line-height: 15px;
  color: $gray-700;
}

.notary-bid-message {
  padding: 8px 15px;
  border-radius: 16px;
  background: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #222222;
  text-align: justify;
}

.word-wrap-break {
  white-space: normal;
  word-wrap: break-word;
  overflow: auto;
}