.alert-informations {
  background: #EEF0FD;
  padding: 35px 15px;
  border-radius: 5px;

  @media(max-width: 991px) {
    padding: 17px;
  }

  .title {
    color: $gray-700;
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
  }

  .alert-count {
    background: $blue;
    display: inline-block;
    border-radius: 50%;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #eee;
    min-width: 20px;
    height: 20px;
    margin: 10px 0;

    @media(min-width: 451px) and (max-width: 991px) {
      margin: 0 0 0 20px;
    }
  }

  .alert-description {
    text-align: center;
    color: #495057;

    @media(max-width: 991px) {
      text-align: justify;
      width: 90%;
      margin-left: 10px;
    }
  }


    .custom-col {
      @media(max-width: 991px) {
        display: flex;
        align-items: center;
        padding: 0 10px 20px;

        &:not(:last-child) {
          border-bottom: 1px solid $white;
        }
      }

      @media (max-width: 450px) {
        display: block;
      }

      .image-wrapper {

        @media(max-width: 991px) {
          width: 40%;

          img {
            max-height: 30px;
          }
        }

        @media (max-width: 450px) {
          width: 100%;
        }
      }

      .description-wrapper {
        @media(max-width: 991px) {
          display: flex;
          align-items: flex-start;
          width: 60%;
        }

        @media (max-width: 450px) {
          width: 100%;
        }
      }
    }
}