$card-background: #f8f9faff;
$subtitle-color: #556EE6FF;
$title-color: #495057FF;

.documents {
  .document-card {
    padding: 18px 10px 18px 10px;
    background-color: $card-background;
    text-align: center;

    .document {
      &-subtitle {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: $subtitle-color;
      }

      &-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $title-color;
      }

      &-description {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: $gray-600;
        margin-top: 30px;
      }
    }

    .button-wrapper {
      position: relative;

      .btn {
        background: $subtitle-color;
        color: $white;
        margin: 30px 0 10px;
        z-index: 2;
        position: relative;
      }

      .divider {
        height: 2px;
        border: 3px dotted rgba(255, 255, 255, 1);
        position: absolute;
        width: 100%;
        transform: translate(-50%);
        left: 50%;
        top: 45px;
        z-index: 1;
      }
    }
  }
}

.mobile-row-doc-wrapper {
  background: #EFF2F7;
  border-radius: 4px;
  padding: 8px 10px;
  margin-bottom: 10px;

  .mobile-row-doc-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .mobile-row-doc-title {
      padding: 0 10px;
      font-size: 13px;
      font-weight: 400;
      line-height: 17px;
      color: $black;
    }

    img {
      max-width: 29px;
    }

    .notarized-badge {
      margin-left: auto !important;
    }
  }

  .mobile-row-doc-body {

    .instructions-description,
    .instructions-title {
      font-size: 13px;
      line-height: 19px;
      color: #74788D;
    }

    .instructions-title {
      font-weight: 500;
    }
  }

  .mobile-row-doc-footer {
    .doc-item-badge-wrapper {
      margin-top: 16px;
    }

    .document-download {
      width: 100%;
      display: flex;
      justify-content: center;
      border: 2px solid #556EE6;
      padding: 6px 10px !important;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      color: #556EE6;
      margin: 10px 0;
      border-radius: 4px;

      .bx-download {
        padding-right: 10px;
      }
    }
  }
}

.must-upload-icon {
  height: 28px;
  margin: -5px;
}

.rush-icon {
 margin: -2px;
}

.print-all-btn {
  @media(max-width: 768px) {
    width: 100%;
    margin: 0 !important;

    > div {
      justify-content: center;
    }
  }
}