.card-preloader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
}

.card.no-shadow {
  box-shadow: none;
}

// Cards
.mav-card {
  position: relative;
  border-radius: 8px !important;

  @media(max-width: 768px) {
    border-radius: 16px !important;
    box-shadow: 2px 2px 11.7px 0px #00000026;

    .badge {
      font-size: 10px;
      font-weight: 700;
      line-height: 15px;
      padding: 0px 6px !important;
      max-height: 17px;
    }

    .opaque-overlay {
      border-radius: 16px !important;
    }
  }

  //  Card body
  .mav-card-body {
    display: flex;
    flex-direction: column;

    @media(max-width: 768px) {
      padding: 16px 10px;
    }
  }

  // Order Details - card
  &.order-details-card {
    @media (max-width: 768px){
      box-shadow: none;
      background: transparent;

      .mav-card-body {
        padding: 16px 10px;
        background: $white;
        box-shadow: 2px 2px 11.7px 0px #00000026;
        margin-bottom: 0;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }
  }

  .opaque-overlay {
    position: absolute;
    opacity: 0.4;
    z-index: 1;
    width: 100%;
    height: 200%;
    background: white;
    border-radius: 8px;
  }

  .bring-forward {
    z-index: 10;
    position: relative;
  }
}

// Contact info
// on order flow and on order details
.order-details-card,
.order-flow {
  .contact-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 768px) {
      display: block !important
    }
  }
}


.mav-card-action-btn {
  width: 100%;
  margin: 10px 0 0 auto;

  @media screen and (min-width: 767px) {
    max-width: 240px;
  }
}

// Notary Information header component
.mav-header-container {
  background-color: #D4DAF9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0.625rem 1.25rem;
  margin-bottom: 20px;


  &.signer {
    background: rgba(206, 212, 242, 0.5);
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width: 768px) {
      &.hidden-mobile {
        display: none;
      }
    }
  }

  .header-title {
    color: $primary;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }

  .header-subtitle {
    font-size: 12px;
    line-height: 18px;
    color: $primary;
  }

  .header-section {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .header-section-icon {
      margin-right: 10px;
      font-size: 20px;
    }

    .header-section-label {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: $gray-700;
      margin: 0;
    }
  }

  .phone-number,
  .email {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: $gray-700;
  }
}