.notary-bid-card {
  .notary-bid-basic-info {
    i {
      font-size: 24px;
      margin-right: 8px;
    }

    p,
    span {
      font-size: 16px;
      font-weight: 500;
    }

    i.bx-timer {
      font-size: 28px;
    }
  }

  .action-button {
    min-width: 130px;
  }

  .dealer-instructions {
    border: 1px solid $gray-400;
    border-radius: 4px;
    padding: 10px;

    @media(max-width: 768px) {
      background: #EFEFEF;
      font-size: 12px;
      line-height: 18px;
      border: 0;
    }
  }

  .basic-info-col {
    background: $white;
    border-radius: 28px;
    padding: 2px 8px;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #495057;

    i {
      font-size: 20px;
      margin-right: 5px;
    }
  }

  .mav-card-title{
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.notary-bid-submitted-card {
  width: 327px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 10px !important;
  background-color: #D4DAF9;
  color: $primary;

  .mav-card-title {
    font-size: 16px;
  }

  .mav-card-subtitle {
    font-size: 12px;
  }
}

.google-maps-distance {
  padding: 10px;
  background: #F8F9FA;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;

  .view-in-map-btn {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    padding: 15px;
    flex-direction: column;

    .view-in-map-btn {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
}