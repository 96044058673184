$gray-650: #808080;

/*********** MY PROFILE ***********/

.my-profile {
  background: #ffffff;
  box-shadow: 0 10px 20px rgba(18, 38, 63, 0.0313726);
  position: relative;
  height: 100%;

  @media(max-width: 768px) {
    background: transparent;
    box-shadow: none;
  }
}

.my-profile-header {
  height: 272px;
  background: #D4DAF9;
  background-image: url(../../images/profile-img.png);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: right top;
  text-align: left;
  padding: 20px;
  border-radius: 4px;

  @media(max-width: 768px) {
    border-radius: 8px;
    text-align: center;
    height: 198px;
    background-size: 55%;
    background-position: right 40px;
  }
}

.my-profile-header>p,
.my-profile-header>h5 {
  color: $primary;
}

.my-profile-avatar {
  border: 10px solid white;
  position: absolute;
  top: 174px;
  left: 20px;

  i {
    font-size: 52px;
  }

  @media(max-width: 768px) {
    top: 90px;
    left: 50%;
    transform: translate(-50%);
  }
}

.my-profile-content {
  padding: 74px 20px 20px 20px;
  border-radius: 4px;

  @media(max-width: 768px) {
    .my-profile-info {
      display: block !important;
      text-align: center;
    }

    .user-name {
      font-size: 20px !important;
      font-weight: 700;
      line-height: 30px;
    }

    .user-role {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    .view-profile-btn>.btn {
      display: block;
      width: 100%;
    }
  }
}

/*********** DOCUMENTS  ***********/
.documents-card {
  height: 100%;

  @media(max-width: 768px) {
    padding-bottom: 0 !important;

    .text-start:first-child {
      .mav-card-action-btn {
        margin-top: 0;
      }
    }
  }
}

/*********** STATISTICS  ***********/
.statistics-card {
  margin: 0;
  height: 100%;

  .statistics-buttons-wrapper {
    text-align: right;

    @media(max-width: 768px) {
      text-align: center;
    }

    .statistics-btn {
      background: $gray-200;
      border-radius: 1px;
      padding: 5px 14px;
      color: $black;
      font-size: 11px;
      line-height: 17px;
      margin: 0 5px;

      &.active {
        background: $primary;
        color: $white;
      }

      @media(max-width: 768px) {
        border-radius: 4px;
        padding: 10px 20px;
      }
    }
  }

  .apexcharts-xaxis {
    display: none;
  }

  @media(max-width: 768px) {
    .apexcharts-legend {
      left: 0 !important;
      top: auto !important;
      flex-direction: row !important;
      padding: 0;

      .apexcharts-legend-series {
        flex-direction: column !important;
      }

      .apexcharts-legend-marker {
        display: block;
        text-align: center;
        margin: 0 auto 10px;
      }

      .apexcharts-legend-text {
        display: block;
        font-weight: 400;
        line-height: 15px;
        font-size: 10px !important;
      }
    }
  }
}

.chart-placeholder {
  text-align: center;

  .placeholder-description {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    color: $gray-650;
  }
}

/*********** MESSAGES ***********/

.messages-card {
  @media(min-width: 768px) {
    margin-bottom: 0;
  }

  @media screen and (min-width: 767px) {
    height: 440px;
  }

  .message-content {

    // apply ellipsis for larger screen sizes
    // because for smaller screens, react-super-responsive-table displays each table entry in a card
    @media screen and (min-width: 767px) {
      width: 35%;
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .horizontal-scroll-table {
    max-height: 350px;
    overflow: auto;
  }
}

.table-badge {
  border-radius: 30px;
  font-size: 11px;
  font-weight: 400;
  padding: 6px 16px;
}

/*********** WIP ORDERS ***********/

.wip-orders-card {
  height: 100%;

  .mobile-card {
    border-radius: 12px;
    box-shadow: 0 4px 8px 0 #0000000D;
    padding: 16px;
    font-size: 11px;
    font-weight: 500;
    line-height: 17px;
    color: $gray-700;
    text-align: left;

    .card-label {
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      color: #999999;
    }

    .badge {
      text-transform: uppercase;
    }
  }
}

/*********** MOBILE CARD ROW ***********/
.mobile-card-row {
  display: block;
  margin-bottom: 36px;
  color: $gray-650;
  text-align: left;

  &-name {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  &-content {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
  }
}

.sender-message-name {
  color: #556ee6;
}

.message-parent {
  white-space: nowrap;
  display: flex;
}


.message-content-mobile {
  max-width: 50px;
  display: inline-block
}

.message-card-mobile {
  max-width: 100px;
  display: inline-block
}

/*********** MESSAGES COMING SOON ***********/

.bullets-list {
  padding-left: 0;
  list-style-position: inside;

  li {
    color: $gray-700;
    font-size: 13px;
  }

  li::marker {
    color: $gray-700;
    content: "• ";
  }
}

.light-purple-bg {
  background-color: #D4DAF9;
}

.email-address {
  font-weight: $font-weight-bold;
  color: $link-color;
}

/*********** STATS AND ACTIONS ***********/

.action-card {
  height: 130px;
  cursor: pointer;
}

.stats-icon {
  align-items: center;
  display: flex;
  font-weight: $fw-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.primary-icon-color {
  background-color: rgba($primary, 0.4);
  color: $primary;
}

.primary-icon-color-light {
  background-color: rgba($primary, 0.15);
  color: $primary;
}

.purple-icon-color {
  background-color: rgba($purple, 0.15);
  color: $purple;
}

.green-icon-color {
  background-color: rgba($green, 0.15);
  color: $green;
}

.icon-circle {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  min-width: 48px;
}

.action-link,
.action-link:hover {
  color: $gray-700;
}

.footer-link {
  position: absolute;
  right: 20px;
  bottom: 10px
}

/*********** CAROUSEL ***********/

.carousel-mav {
  height: 440px;

  .carousel-status,
  .control-next,
  .control-prev {
    display: none;
  }

  .slide {
    background: white;
    padding-bottom: 40px;
  }

  .slide-title {
    font-size: 38px;
    font-weight: 700;
    color: white;
    text-align: left;
    font-family: 'Lato', sans-serif;
    margin-bottom: 20px;

    @media screen and (max-width: 1037px) {
      font-size: 24px;
    }
  }

  .slide-title-esign {
    max-width: 350px;
  }

  .slide-title-ink {
    max-width: 400px;
  }

  .slide-content {
    font-size: 22px;
    color: white;
    text-align: left;
    font-family: 'Lato', sans-serif;

    @media screen and (max-width: 1037px) and (min-width: 768px) {
      font-size: 16px;
    }
  }

  .long-text-resize {
    @media screen and (max-width: 1600px) and (min-width: 768px) {
      .slide-title {
        font-size: 30px;
      }

      .slide-content {
        font-size: 18px;
      }
    }
  }

  .position-slide-text {
    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 30px;
      left: 60px;
    }
  }

  .slide-responsive {
    height: 100%;
    padding: 0;

    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      padding: 20px;
    }
  }

  .slide-background {
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px) {
      background-position: top center;
    }
  }

  #slide-1 {
    background-image: url(../../images/carousel/vid.png);
  }

  #slide-2 {
    background-image: url(../../images/carousel/eSign.png);
    background-position: top 0px left -60px;
  }

  #slide-3 {
    background-image: url(../../images/carousel/inkSign.png);
    margin: -1px; // fix glitch
    background-position: top 0px left -30px;
  }

  #slide-4 {
    background-image: url(../../images/carousel/seal.png);

    @media screen and (min-width: 768px) {
      background-position: top right;
    }
  }
}

.carousel {
  height: 100%;

  .control-dots {
    margin: 10px 0px;
  }

  .dot {
    background: white !important;
  }

  .slider-wrapper,
  .slider,
  .slide,
  .item {
    height: 100%;
  }

  .slide {
    padding: 0;
  }
}

.carousel-root {
  height: 100%;
}

/*********** DOUGHNUT CHART ***********/

.btn-active {
  color: black !important;
  border: none;
}

.btn-active:focus {
  color: #ffff !important;
}

.img-store {
  width: 6em !important;
  height: auto;
  margin-right: 0.5em;
  margin-top: 0.5em;
}

/*********** TOP ***********/

.top-card {
  height: 440px;
}

.top-store-name {
  color: $gray-700;
  font-size: 13px;
}

.progress-col {
  width: 45%;
}

.badge-placeholder {
  width: 32px;
  height: 32.24px;
}

/*********** TIME TO COMPLETION ***********/

.time-to-completion-card {
  height: 438px;
}

/*********** CALL A CUSTOMER ***********/

.call-a-customer-card {
  height: 440px;
}

/*********** REMOTE CUSTOMER PERFORMANCE ***********/

.remote-customer-perf-card {
  height: 440px;

  @media screen and (max-width: 992px) {
    overflow-y: auto;
  }
}

.overlap-chart {
  z-index: 999;
}

.order-status-stats {
  width: 100%;

  tr:last-child {
    td {
      border: none;
    }
  }

  @media screen and (min-width: 992px) {
    position: absolute;
    left: 20px;
    width: 366px;
    top: -10px;
  }
}

#apexchartsdonutxchart {
  div.apexcharts-legend {
    inset: auto 0px 0px !important; // moves the legend to the left
    // inset is not available in some browsers like olders version of Safari
    // in this case, fallback to:
    top: auto !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0px !important;
  }
}

/*********** COMMON ***********/

// used to add an horizontal scrollbar when content is too wide
.horizontal-scroll-table {
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
}

.horizontal-scroll-table>table {
  @media screen and (max-width: 768px) {
    width: 200%;
  }
}

.dashboard {
  .card {
    border-radius: 4px;
    padding: 24px;

    .card-body {
      padding: 20px 0;

      table {
        tr {

          td:first-of-type,
          th:first-of-type {
            padding-left: 0;
          }
        }
      }
    }

    @media(max-width: 768px) {
      text-align: center;
      padding: 24px 10px;

      .card-title {
        margin-bottom: 30px;
      }

      .card-footer {
        display: block !important;
        padding: 0;

        .btn {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.messages-table {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%
}