//
// _badge.scss
//


.badge {
  &[href] {
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18);

  &[href] {
    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge {
  &.bg-#{$color} {
    &[href] {
      &:hover,
      &:focus {
        background-color: darken($value, 4%) !important;
      }
    }
  }
}

.badge{
  &.bg-light{
    color: $body-color;

    &[href] {
      &:hover,
      &:focus {
        color: $body-color;
      }
    }
  }
}

  .badge-soft-#{$color} {
      @include badge-variant-soft($value);
  }

}

.rounded-pill{
   padding-right: .6em;
    padding-left: .6em;
}

// Dark badge

.badge {

  &.bg-dark{
    color: $light;
  }

  &.bg-assigned-notary {
    background: $cyan;
  }

  &.bg-viewed-notary {
    background: linear-gradient(180deg, $white -98.8%, $cyan 100%);
  }

  &.bg-docs-received {
    background: $pastel-blue;
    color: $steel-blue;
  }

  &.bg-appointment-sent {
    background: $lavender;
  }

  &.bg-appointment-declined {
    border: 1px solid $dark-red;
    color: $burgundy;
    background: linear-gradient(0deg, rgba(146, 64, 64, 0.3), rgba(146, 64, 64, 0.3)), linear-gradient(0deg, $pale-pink, $pale-pink);
  }

  &.bg-appointment-accepted {
    border: 1px solid $dark-blue;
    color: $midnight-blue;
    background: linear-gradient(0deg, rgba(48, 99, 145, 0.3), rgba(48, 99, 145, 0.3)), linear-gradient(0deg, $pale-blue, $pale-blue);
  }

  &.bg-docs-printed {
    background: $powder-blue;
  }

  &.bg-docs-uploaded {
    background: $periwinkle-blue;
  }

  &.bg-completed-notary {
    background: $green;
  }
}