//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
    width: 32px;
    height: 32px;
    padding: 0;
    text-align: center;
    line-height: 32px;
  }
}

.pagination-parent {
  max-width: 900px !important;
  overflow-x: auto;
  padding-bottom: 15px;
  box-sizing: content-box;
  white-space: nowrap;
}

.pagination-parent>li {
  display: inline-block !important;
}

@media screen and (max-width: 550px) {
  .pagination-parent {
    max-width: 300px !important;
  }
}