// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

// Accounting Card
.accounting-card {
  .content-header,
  .body-row,
  .card-title {
    border-bottom: 1px solid $gray-200;
    padding-bottom: 15px;
  }

  .body-row {
    padding-top: 15px;
  }

  .content-header {
   font-weight: 700;

    @media(max-width: 768px) {
      color: #999;
      font-weight: normal;
    }
  }

  .content-footer {

    @media(min-width: 768px) {
      text-align: right;
    }

    @media(max-width: 767px) {
      display: flex;
      justify-content: space-between;
    }

    .total-cost-label {
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
    }

    .total-cost-value {
      font-size: 20px;
      font-weight: 500;
      line-height: 29px;
      margin-left: 50px;
    }
  }

  @media(max-width: 768px) {
    font-size: 11px;
    font-weight: 500;
    line-height: 17px;

    .content-footer {
      .total-cost-label,
      .total-cost-value {
        font-size: 11px;
        font-weight: 500;
        line-height: 17px;
      }
    }
  }
}
