.show-counter {
  display: flex;

  .countdown {
    font-size: 16px;
    font-weight: 700;
    padding: 0 5px;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media(max-width: 768px) {
      font-size: 13px;
    }
  }

  p {
    margin: 0;
  }
}