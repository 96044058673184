.contact-btn {
  height: 100%;

  .contact-btn-icon {
    width: 22px;
    height: 100%;
    margin: auto 0;
    padding-bottom: 3px;
  }
}

.contact-modal {
  border-radius: 20px;
  font-family: Lato;
  width: 335px;
  position: relative;
  bottom: 60px;
}

.contact-modal-header {
  text-align: center;
}

.contact-modal-title {
  font-size: 16px;
  font-weight: 700;
}

.contact-modal-subtitle {
  color: $gray-800;
  margin: 0;
  font-size: 14px;
}

.contact-item-title {
  font-weight: 700;
  color: #2B69B8;
  font-size: 16px;
  margin-bottom: 5px;
}

.contact-item-subtitle {
  color: $gray-600;
  margin: 0;
}

.line {
  background-color: #D8D8D8;
  height: 5px;
  width: 50px;
  margin: -10px auto 20px auto;
  border-radius: 100px;
  transform: rotate(-180deg);
}

.contact-offcanvas {
  overflow: hidden;
  height: 33vh;
  min-height: 278px;

  &.expanded {
    height: 42vh;
    min-height: 353px;
  }

  &.offcanvas-bottom {
    border-radius: 16px 16px 0px 0px;
  }

  .offcanvas-body {
    padding-top: 24px;
  }

  .contact-modal-title {
    font-size: 24px;
  }

  .contact-modal-header {
    padding-bottom: 20px;
    padding-top: 0;
  }
}

.header-btn {
  background: #fff;
  color: #1D71B8;
  font-size: 13px;
  padding: 4px 15px 4px 8px;
  border-radius: 30px;
  margin-right: 0px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #1D71B8;

  @media(max-width: 768px) {
    border: 2px solid #556EE6;
    border-radius: 4px;
    font-weight: 500;
    color: #556EE6;
    text-align: center;
    text-transform: capitalize;
  }
}