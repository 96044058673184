/************* Order details and order view card *************/
.order-card.mav-card,
.order-details-card.mav-card {
  .section-info {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: right;
    color: $gray-700;
  }

  &.list-view {
    .mav-card-header {
      height: 100%;
      overflow: hidden;
      border-radius: 8px 0 0 8px;

      .card-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      &.additional-signer {
        padding: 0 !important;

        .header-additional-signer {
          border-radius: 0 8px 0 8px;
        }

        .header-customer {
          border-radius: 8px 0 8px 0;
        }

        .card-header {
          height: 50%;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.order-card.mav-card {
  .list-view-row {
    min-height: 175px;
  }
}

/************* Order Header *************/
.mav-card-header {
  background-color: #CED4F2;
  border-radius: 8px 8px 0 0;

  @media(max-width: 768px) {
    background: $blue;
    padding: 10px 10px 20px;
    border-radius: 16px 16px 0px 0px;

    .contact-info {
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      color: $white;
    }
  }

  // Header with two signers
  &.additional-signer {
    background-color: $white;

    @media(max-width: 768px) {
      background-color: $white;
      padding: 0;

      .order-flow & {
        background: transparent;
      }
    }

    .header-customer {
      background-color: #CED4F2;
      margin-bottom: 5px;

      @media(max-width: 768px) {
        background: $blue;
        border-radius: 16px;
        margin-bottom: 10px;
      }
    }

    .header-additional-signer {
      background: rgba(206, 212, 242, 0.5);
      border-radius: 0.25rem 0.25rem 0 0;

      @media(max-width: 768px) {
        background: $blue;
        border-radius: 16px 16px 0 0;
      }
    }
  }

  .card-header {
    background-color: transparent;
  }

  .mav-card-title {
    font-size: 20px;
    line-height: 30px;
    color: $primary;

    @media (max-width: 768px) {
      color: $white;
      margin: 0;
    }
  }

  .mav-card-subtitle {
    font-size: 12px;
    color: $primary;

    @media (max-width: 768px) {
      color: $white;
    }
  }

  .order-number {
    color: $white;
  }
}

/************* Order Flow *************/
.order-flow-accordion {
  .accordion-item {
    border: 0;
  }

  >.accordion-item {
    margin-bottom: 15px;

    @media(max-width: 768px) {
      border-radius: 8px;

      &:not(:last-child) {
        box-shadow: 2px 2px 11.7px 0px #00000026;
      }
    }

    // Accorderon header
    >.accordion-header {
      background-color: #D4DAF9;
      display: flex;
      align-items: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .accordion-button {
        box-shadow: none;
      }

      &.docs-accordion-header {
        background: #EEF0FD;
      }

      &.accordion-item-success {
        background-color: $success;
        box-shadow: 2px 2px 11.7px 0px #00000026;

        h6.accordion-title {
          color: white;
        }

        .accordion-button.collapsed::after,
        .accordion-button:not(.collapsed)::after {
          background: url(../../images/order/checked-icon.svg);
        }
      }
    }

    // Accordeon body
    >.accordion-collapse {

      .accordion-body {
        background-color: $white;

        @media(max-width: 768px) {
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        .col-form-label {
          color: #999999;
        }
      }
    }
  }

  /* Docs accordion - Upload documents section */
  .docs-required-accordion {
    border-radius: 8px;

    .docs-required-header {
      .accordion-button {
        background-color: #EEF0FD;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: none !important;
      }
    }

    .docs-required-body {
      .accordion-body {
        padding: 0;
      }
    }

    @media(max-width: 768px) {
      box-shadow: 2px 2px 11.7px 0px #00000026;

      .accordion-item {
        padding: 0;

        // Accordeon body
        .docs-required-body {
          .doc-item-mobile {
            background: #EFEFEF;
            border-radius: 4px;
            padding: 16px 8px;

            h6.accordion-title {
              max-width: 90%;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              color: #495057;
            }

            .doc-item-mobile-header {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }

  /* Additional documents - Mobile */
  .additional-documents-mobile-wrapper {
    @media(max-width: 768px) {
      box-shadow: 2px 2px 11.7px 0px #00000026;
      border: 1px solid #CED4DA;
      border-radius: 16px;
      margin: 0 -10px;

      >.doc-item {
        justify-content: space-between;
        background: #EEF0FD;
        border: 0;
        padding: 10px 5px 10px 15px;
        border-radius: 16px;
        margin: 0 !important;

        &.form-open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .form-label {
          font-size: 13px !important;
          margin: 0 !important
        }

        .form-check {
          margin: 0 !important;
        }
      }

      .doc-pages {
        .mobile-uploaded-doc {
          background: #EFEFEF;
          border-radius: 5px;
          padding: 10px 10px 20px;

          &:first-child {
            margin-top: 20px;
          }

          .doc-item {
            padding: 0;
            margin: 0;

            .doc-item-title {
              font-size: 14px;
              line-height: 21px;
              font-weight: 500;
            }
          }

          .doc-pages {
            .docs-upload-wrapper {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;

              >div.doc-page {
                width: 48%;

                button {
                  padding: 5px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .mobile-add-doc {
        padding: 5px;

        .mobile-add-doc-title {
          display: block;
          text-align: center;
          margin: 5px 0 15px;
          font-size: 14px;
          line-height: 21px;
        }

        .mobile-add-doc-wrapper {
          display: block !important;

          .mobile-input-wrapper {
            margin: 0 !important;

            input.form-control {
              border-color: #EFEFEF;
              border-radius: 5px;
              font-family: Poppins;
              font-size: 12px;
              font-weight: 500;
              line-height: 18px;
              text-align: left;
              color: #999;
            }
          }

          button {
            border: 2px solid #556EE6;
            border-radius: 4px;
            width: 100%;
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  /* Did you collect payment Section - Mobile */
  .mobile-collect-payment-header {
    display: flex;
    align-items: center;

    .form-label {
      font-size: 13px;
      font-weight: 500;
      line-height: 19.5px;
    }
  }

  @media(max-width: 768px) {
    .mobile-collect-payment-body {
      .collect-form-title {
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        color: #74788D;
        padding: 0;
        margin: 0 0 10px;

        +div {
          display: block !important;
        }
      }

      .form-check-row {
        border: 1px solid #74788D;
        border-radius: 55px;
        display: flex;
        align-items: center;
        padding: 10px 8px;
        margin-bottom: 15px;

        .form-check-input {
          margin-top: 0;
        }

        .form-label {
          margin: 0;
          font-size: 12px;
          line-height: 18px;
          color: #495057;
        }

        &.checked {
          background: #90A0EE;
          border: 1px solid #90A0EE !important;

          .form-label {
            color: #fff;
          }
        }
      }
    }
  }

  /* Return docs to dealership tab - Mobile */
  .return-docs-accordion {

    @media(max-width: 768px) {
      .accordion-body {
        background: transparent !important;
        padding: 0;

        .return-docs-card-head {
          background: #fff;
          padding: 16px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    .return-docs-card {
      background: $white;
      color: $gray-700;
      border-radius: 4px;
      padding: 16px;

      .return-docs-card-header {
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        opacity: 0.6;
      }

      .return-docs-card-body {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;

        .badge {
          padding: 4px 10px;
          font-size: 10px;
          font-weight: 500;
          line-height: 15px;
          display: inline-block;
        }
      }
    }
  }
}

/************* Accorderon Instruction Component *************/
.instructions-accordion {
  .accordion-item {
    border: 0;

    .accordion-header {
      border-radius: 4px;
      border: 0px;
      color: #fff;

      .accordion-button {
        background: #556EE6;
        border-radius: 4px;
        padding: 10px;
        box-shadow: none;
        color: #fff;
        font-weight: 500;

        &.collapsed {
          background: #CED4DA;
          color: #222222;
        }

        &:after {
          background-image: url('../../images/instructions/arrow-down.svg');
        }

        &:not(.collapsed)::after {
          background-image: url('../../images/instructions/arrow-up.svg');
          transform: none;
        }
      }
    }

    .accordion-collapse {
      &:last-of-type & {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .accordion-body {
        background: #EEF0FD;
        border-radius: 4px;
        padding: 10px;
        margin-top: 20px;

        .instructions-row {
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

/************* Other *************/
.filter-btn {
  font-size: 10px;
  height: 32px;

  &:not(.active) {
    background-color: transparent;
    color: $black;
    border: none;
  }
}

a.mav-card-action-btn.download-link {
  @media screen and (min-width: 767px) {
    max-width: 240px;
  }
}

a.document-download {
  width: 50%;

  &.no-event {
    pointer-events: none;
  }

  @media screen and (min-width: 1600px) {
    max-width: 30%;
  }
}

.document-download-info {
  text-align: left;

  .instructions-title {
    font-size: 14px;
    line-height: 21px;
    margin: 5px 0 0 0;
    font-weight: $headings-font-weight;
    color: $gray-600;
  }

  .instructions-description {
    color: $gray-600;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 5px;
  }
}

.doc-item {
  padding: 10px 16px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $gray-200;
  margin-bottom: 16px;
}

.doc-item-title {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  margin-right: 30px;

  @media(max-width: 767px) {
    font-size: 12px;
  }
}

.doc-item-badge {
  font-size: 10px;
  font-weight: 400;
  font-family: "Lato";
  background-color: $primary;
  margin-right: 12px;
  margin-top: 4px;
  padding: 4px 8px;

  @media(max-width: 767px) {
    display: inline-flex;
    align-items: center;
    height: 20px;
  }
}

/************* Upload document/picture Component (order flow step) *************/
.doc-pages {
  display: flex;
  flex-wrap: wrap;

  @media(max-width: 768px) {
    justify-content: space-between;
  }
}

.doc-page {
  position: relative;
  display: inline-block;

  @media(min-width: 768px) {
    margin-right: 12px;
  }
}

.doc-page-mobile-wrapper {
  width: 49%;
  margin-bottom: 10px;
}

.doc-page-mobile {
  width: 100%;
}

.doc-page-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 155px;
  max-width: 100%;
  text-align: center;
  pointer-events: auto;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #CED4DA;
  margin-bottom: 8px;
  position: relative;

  @media(max-width: 768px) {
    width: 100%;
    padding: 10px;
    background: #fff;
    border: 0;
  }

  &.is-rejected {
    border-color: #f46a6a;
  }

  &.is-accepted {
    border-color: #34c38f;
  }

  img {
    max-width: 100%;
    height: 178px;

    @media(max-width: 768px) {
      height: 100%;
    }
  }

  .noimg {
    width: 100px;
    height: 100px;
  }

  .rejected-badge,
  .review-badge {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  .mdi-spin {
    font-size: 32px;
  }
}

.ink-sign-gallery {
  &.pswp {
    z-index: 1040;
  }

  .pswp__content {
    text-align: center;
  }

  .ink-sign-gallery-item {
    margin: 50px auto 0;
    display: inline-block;
    background-color: white;

    .ink-sign-gallery-item-img {
      border: 5px solid #eff2f7;
      // viewport - spacing
      max-height: calc(100vh - 100px - 100px);
    }

    .ink-sign-gallery-item-btns {
      padding: 32px;
      text-align: right;
    }
  }
}

#capture-parent {
  width: 100vw;
  min-height: 100vh;
  background: #171717;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1020;

  #contract-name {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 14px;
    color: white;
    text-align: center;
    padding-top: 32px;
  }

  #contract-page {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.35px;
    color: white;
    width: 85%;
    margin: auto;
    background: rgba(34, 34, 34, 0.5);
    border-radius: 15px;
    margin-top: 17px;
    height: 30px;
  }

  #camera-wrapper {
    width: 100%;
    top: 136px;
    text-align: center;
    display: flex;

    #capture-wrapper {
      width: 75%;
      position: absolute;
      left: 50%;
      top: 136px;
      transform: translate(-50%, 0);
      border-radius: 7px;
      padding: 20px 13px;
      border: 1px solid white;
      text-align: center;
      display: flex;
    }

    #webcam {
      width: 310px;
      height: 430px;
      position: absolute;
      border-radius: 7px;
      padding: 20px 13px;
      border: 1px solid white;
      left: 50%;
      top: 136px;
      transform: translate(-50%, 0);
    }

    #capture-preview {
      display: block;
      width: 100%;
    }
  }
}

#preview-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #222222;
  padding-bottom: 10px;

  .powered {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    text-align: center;
    color: white;
    margin: 20px;
  }

  #preview-actions-row {
    display: flex;
    justify-content: space-around;

    .preview-action {
      flex-basis: 25%;
      text-align: center;

      button {
        width: 50px;
        height: 50px;
        background: rgba(43, 105, 184, 0.2);
        border-color: transparent;
        border-radius: 50%;
        line-height: 50px;

        i {
          color: #0191ff;
          font-size: 18px;
        }

        &.primary {
          background: #0191ff;

          i {
            color: white;
          }
        }
      }

      label {
        display: block;
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
}

/************* Youtube modal - from dashboard *************/
.modal-header-dashboard {
  border-bottom: 0px !important;
}

.modal-body-dashboard {
  padding: 20px 50px 40px 50px;

  @media screen and (max-width: 767px) {
    padding: 20px 20px 40px 20px;
  }
}

.player {
  border-radius: 20px !important;
  margin-top: 20px
}

.all-docs-shipped-icon {
  margin-right: 5px;
}

.blurred {
  opacity: 0.5;
  pointer-events: none;
}

#continue-btn-wrapper {
  width: 240px;
  display: inline-block;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.inactive-link {
  pointer-events: none;
  cursor: default;
}

.or-upload-pdf {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  align-self: center;
  margin: 10px 15px 0 5px;

  @media(max-width: 768px) {
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: center;
    display: block;
  }
}

.capture-doc-as-pdf {

  .mdi-file-pdf-outline {
    font-size: 64px;
  }
}

.captured-as-pdf-icon {
  font-size: 24px;
  margin-top: -10px;
  margin-bottom: -10px;
}

.accordion-item-parent {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #495057;

  @media(min-width: 768px) {
    border: 1px solid rgba(239, 239, 239, 1);
    border-radius: 5px;
    padding: 15px;
    background: transparent;
  }

}

/************* AOI banner *************/
.aoi-thumbprint-banner {
  background: #EEF0FD;
  padding: 14px 10px;
  border-radius: 4px;
  text-align: justify;
  align-items: center;

  .notary-bid-card & {
    display: none !important;
  }

  @media(max-width: 767px) {
    align-items: flex-start;
    padding: 8px 10px;
  }
}

/************* Order services section -
aoi / rush / upload docs / preview docs info messages *************/
.order-services {
  .order-services-row {
    border: 1px solid #EFEFEF;
    padding: 4px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 12px;
    background: $white;
  }
}

/************* Order additional info - rush / upload docs / preview docs *************/
.order-info {
  font-size: 13px;
  line-height: 20px;
  color: #495057;

  &-title {
    font-weight: 500;
    color: #999999;
  }

  &-subtitle {
    font-weight: 700;

    &.is-dealership {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .grid-view & {
    .order-info-description {
      display: none;
    }
  }
}


/************* User info box (dealership / scheduler) *************/
.user-info-box {
  &-role {
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    color: #7B7B7B;
  }

  &-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &-phone {
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    color: #495057;
  }

  &-email {
    font-size: 11px;
    font-weight: 500;
    line-height: 19.5px;
    color: #495057;
  }

  &-desktop {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

}

.scheduler-img {
  margin-right: 8px;
  margin-top: 10px
}

.scheduler-img-desktop {
  margin-right: 5px;
  margin-top: 0px
}

/************* Info message box (from order flow) *************/
.notary-order-info {
  background-color: #DDE2FA;
  color: #33428A;
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  padding: 8px 15px;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid #CCD4F8;
}

/************* Group special instructions (mobile stile) *************/
.group-special-instructions {
  @media(max-width: 768px) {
    .description {
      background: #DAE9F9;
      padding: 10px;
      border-radius: 4px;
      font-size: 12px;
      line-height: 18px;
      color: #74788D;
    }
  }
}

/************ Print all docs, lottie animation *********************/

.lottie-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 10000;
  align-items: center;

  .overlay {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: black;
    position: absolute;
  }

  .lottie-parent {
    width: 700px;
    height: 500px;
    z-index: 10001;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .animation-title {
      margin-top: 50px;
      font-size: 22px;
      color: #556EE6;
      font-weight: 600;
    }

    .animation-description {
      margin-top: 10px;
      font-size: 20px;
      color: #556EE6;
    }
  }

  .lottie-obj {
    z-index: 10001;
  }
}

/************* Lottie animation (mobile stile) *************/
.lottie-container {
  @media(max-width: 768px) {
    .lottie-parent {
      width: 85%;
      height: 450px;

      .animation-title {
        font-size: 18px;
      }

      .animation-description {
        font-size: 15px;
      }
    }
  }
}


/************* This input font size is used to prevent zoom in when input is focused on mobile devices*************/
input,
textarea {
  @media(max-width: 768px) {
    font-size: 16px !important;
  }
}