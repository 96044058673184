// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }

  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

td {
  &.no-border {
    border: 0;
  }

  &.text-decoration-underline {
    text-decoration: underline;
  }
}

.zebra-striped-table {
  tr,
  td {
    border: 0;
  }

  tbody {
    th {
      border-color: transparent;
    }

    tr:nth-child(odd) {
      background-color: $gray-100;
    }
  }
}

