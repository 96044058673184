.terms-text {
  text-align: justify;
  margin: 1rem 1rem 1rem 0;
}

#terms-tabs {
  .nav-link {
    padding: 17px 32px;
    font-weight: 700;

    &.active {
      background-color: $primary;
      color: white;
    }
  }
}

.terms-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.terms-section {
  padding: 12px 0;
  font-size: 16px;
}