$close-btn-color: #323232;

.tracking-modal {
  .modal-content {
    padding: 35px 20px;

    .tracking-modal-header {
      .btn-close {
        position: absolute;
        right: 10px;
        top: 28px;
        color: $close-btn-color;
      }

      .tracking-modal-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 29px;
      }
    }

    .modal-body {
      padding: 0;

      .table {
        thead {
          background: $gray-100;
        }
      }

      .package-number {
        font-size: 13px;
        line-height: 20px;
        padding: 2px 6px;
        border-radius: 27px;
        background: rgba(43, 105, 184, 0.2);
        font-weight: 500;
        color: $blue;

        @media(max-width: 768px) {
          margin: 5px 0 0 0 !important;
          display: inline-block;
        }
      }

      .btn {
        min-width: 140px;
      }
    }
  }
}

@media(max-width: 767px) {
  .modal-dialog {

    td, th {
      font-size: 10px;
    }

    .modal-content {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}