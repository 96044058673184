.col-notif-1 {
  display: flex;
  align-items: center;

  .checkbox-wrapper-mail {
    margin-left: 20px;
    vertical-align: middle;
  }

  div,
  .title {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.col-notif-2 {
  display: flex;
  justify-content: flex-end;

  .date {
    display: inline;
    position: absolute;
    right: 40px;
  }
}

.notification-item {
  .d-flex {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: $gray-300;
    }
  }

  .unread {
    background-color: $gray-300;
    font-weight: 500;
    color: darken($dark, 5%);

    a {
      color: darken($dark, 5%);
      font-weight: 500;
    }
  }
}

.message-list {
  margin-bottom: 0;
}

.notification-list {
  margin-bottom: 150px;

  @media(max-width: 768px) {
    padding: 0;
    margin-left: -12px;
    margin-right: -12px;
    width: auto;

    .row:first-of-type {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .btn-toolbar {
    .dropdown {
      margin-left: auto;
    }
  }

  h6.pagination-label {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    margin: 0;
    color: rgba(116, 120, 141, 1);
  }



  .mobile-notification-row {
    border-bottom: 1px solid rgba(231, 231, 231, 1);
    padding: 10px 16px;

  &.unread {
    background-color: $gray-300;
  }

  &.active {
    background: rgba(85, 110, 230, 0.25);
  }

  .title {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(42, 48, 66, 1);
  }

  .message,
  .date {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
  }

  .message {
    color: #2a3042;
    height: 16px;
    margin-bottom: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .date {
    color: rgba(116, 120, 141, 1);
  }

  .checkbox-wrapper-mail {
    border-radius: 5px;

    label:before {
      color: $white;
      position: absolute;
      margin-top: 0;
      left: 0;
      background: rgba(85, 110, 230, 1);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

  .notification-list-footer {
    padding: 0 16px;
  }
}